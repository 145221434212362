import React, { useState } from 'react'
import ReactSVG from 'react-svg'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './play-button.css'
import { useGetWatchLocation } from '../../hooks/useGetWatchLocation'
import { DeepLink } from '../../hoc/deep-link'

import {
  PLAY_BUTTON_PLAY_ICON,
  PLAY_BUTTON_REPLAY_ICON,
  PLAY_BUTTON_KIDS_PLAY_ICON,
  PLAY_BUTTON_TRAILER_TEXT
} from '../../containers/play-button/constants'

import play from '../../../images/new-play.svg'
import replay from '../../../images/new-replay.svg'
import kidsPlay from '../../../images/play-kids.svg'
import { E2E_SELECTORS } from '../../e2e-selectors'
import ConfirmationModal from '../shared/confirmation-modal'
import { KIDS_PROFILE_ADD_SUBSCRIPTION_ERROR_MESSAGE } from '../../constants'

function PlayButton({
  icon,
  size,
  ctaPrefix,
  ctaSuffix,
  time,
  progressPercentage,
  displayCta,
  displayTime,
  displayProgress,
  onClick,
  clickable,
  watchUrl,
  className,
  ctaClass,
  ctaTimeClass,
  playIconWrapperClassName,
  skipIntro,
  hideTime,
  fromEpisodeCountdown,
  fromMyRentals,
  isMyRentalsBadge,
  shouldUseRentalStyle,
  e2eIdentifier,
  isRental = false,
  isKid,
  hasLinearGradient,
  isMouseHoverRemoveIcon,
  watchable
}) {
  const icons = {
    [PLAY_BUTTON_PLAY_ICON]: play,
    [PLAY_BUTTON_REPLAY_ICON]: replay,
    [PLAY_BUTTON_KIDS_PLAY_ICON]: kidsPlay
  }
  const isKidsPlay = icon === PLAY_BUTTON_KIDS_PLAY_ICON
  const clickableClass = clickable ? styles.clickable : styles.notClickable
  const watchLocation = useGetWatchLocation(watchUrl, skipIntro, isKid, watchable)
  const fromEpisodeCountdownAndTrailer = fromEpisodeCountdown &&
    ctaPrefix === PLAY_BUTTON_TRAILER_TEXT

  // REMOVE ME, this is for outlander issue only, remove after season 5 is out
  if (!watchUrl && /\/series\/outlander/.test(location.pathname)) {
    return null
  }

  const [isMouseHover, setIsMouseHover] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const shouldShowProgressBar = displayProgress && progressPercentage

  const handleOnClick = (event) => {
    if (isKid && !watchable) {
      setShowErrorMessage(true)
    }
    // Pass event into onClick to handle the event.preventDefault() for chromecast
    onClick(event)
  }

  const onConfirmClick = () => {
    setShowErrorMessage(false)
  }

  if (showErrorMessage) {
    return (
      <ConfirmationModal
        header="Hmmm"
        message={KIDS_PROFILE_ADD_SUBSCRIPTION_ERROR_MESSAGE}
        confirmText="OK"
        onConfirmClick={onConfirmClick}
        customWrapperClass={styles.errorMessageWrapper}
      />
    )
  }

  return (
    <div
      className={classNames(
        styles.playWrapper,
        styles[size],
        clickableClass,
        {
          [styles.fromMyRentals]: shouldUseRentalStyle && fromMyRentals && isMyRentalsBadge,
          [styles.forKids]: isKidsPlay
        }
      )}
      data-lbx-e2e={E2E_SELECTORS.HOT_SPOT_PLAY_BUTTON}
      onMouseEnter={() => setIsMouseHover(true)}
      onMouseLeave={() => setIsMouseHover(false)}
    >
      <DeepLink
        isPlayButton
        data-lbx-e2e={e2eIdentifier}
        to={watchLocation}
        onClick={handleOnClick}
        className={classNames(
          styles.buttonCta,
          clickableClass,
          {
            [styles.fromMyRentals]: shouldUseRentalStyle && fromMyRentals && isMyRentalsBadge,
            [styles.isRental]: isRental,
            [styles.forKids]: isKidsPlay
          }
        )}
      >
        <div
          className={classNames(
            styles.clickableLink,
            {
              [styles.forKids]: isKidsPlay,
              [styles.linearGradient]: isMouseHover && hasLinearGradient
            }
          )}
        >
          <div
            className={classNames(
              styles.linkWrapper,
              {
                [styles.forKids]: isKidsPlay
              }
            )}
          >
            { (isMouseHover || isMouseHoverRemoveIcon) && (
              <div
                className={classNames(
                  styles.iconWrapper,
                  clickableClass,
                  className,
                  {
                    [styles.withProgressBar]: shouldShowProgressBar,
                    [styles.fromMyRentals]: fromMyRentals,
                    [styles.forKids]: isKidsPlay
                  }
                )}
              >
                <ReactSVG
                  src={icons[icon]}
                  evalScripts="never"
                  beforeInjection={(svg) => {
                    svg.setAttribute('class', classNames(
                      styles.icon,
                      styles[icon],
                      {
                        [styles.fromEpisodeCountdown]: fromEpisodeCountdownAndTrailer,
                        [styles.fromMyRentals]: fromMyRentals
                      }
                    ))
                  }}
                  className={classNames(
                    styles.navArrow,
                    playIconWrapperClassName
                  )}
                />
              </div>
            )}
            {displayCta && (
              <div
                className={classNames(
                  styles.ctaWrapper,
                  {
                    [styles.forKids]: isKidsPlay
                  }
                )}
              >
                {ctaPrefix && (
                  <span
                    className={classNames(
                      styles.buttonLabel,
                      ctaClass,
                      { [styles.fromMyRentals]: fromMyRentals }
                    )}
                    data-lbx-e2e={E2E_SELECTORS.RENT_MOVIE}
                  >
                    {ctaPrefix}
                  </span>
                )}
                {ctaSuffix && (
                  <span className={styles.buttonEpisodeNumber}>
                    &nbsp;
                    {ctaSuffix}
                  </span>
                )}
              </div>
            )}
            {
              displayTime &&
              !hideTime && (
                <div
                  className={classNames(
                    styles.buttonTime,
                    ctaTimeClass,
                    {
                      [styles.forKids]: isKidsPlay
                    }
                  )}
                >
                  {time}
                </div>
              )
            }
          </div>
          {
            shouldShowProgressBar
              ? (
                <div
                  className={classNames(
                    styles.progressTube,
                    {
                      [styles.forKids]: isKidsPlay
                    }
                  )}
                >
                  <div className={styles.progressBar} style={{ width: `${progressPercentage}%` }} />
                </div>
              )
              : null
          }
        </div>
      </DeepLink>
    </div>
  )
}

PlayButton.propTypes = {
  watchUrl: PropTypes.oneOfType([
    PropTypes.oneOfType([PropTypes.object]),
    PropTypes.string
  ]),
  icon: PropTypes.oneOf(
    [
      PLAY_BUTTON_PLAY_ICON,
      PLAY_BUTTON_REPLAY_ICON,
      PLAY_BUTTON_KIDS_PLAY_ICON
    ]
  ).isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  progressPercentage: PropTypes.number.isRequired,
  ctaPrefix: PropTypes.string,
  ctaSuffix: PropTypes.string,
  time: PropTypes.string,
  displayCta: PropTypes.bool.isRequired,
  displayTime: PropTypes.bool.isRequired,
  displayProgress: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  clickable: PropTypes.bool,
  className: PropTypes.string,
  ctaClass: PropTypes.string,
  ctaTimeClass: PropTypes.string,
  skipIntro: PropTypes.bool,
  playIconWrapperClassName: PropTypes.string,
  hideTime: PropTypes.bool,
  fromEpisodeCountdown: PropTypes.bool,
  fromMyRentals: PropTypes.bool,
  isMyRentalsBadge: PropTypes.bool,
  shouldUseRentalStyle: PropTypes.bool,
  e2eIdentifier: PropTypes.string,
  isRental: PropTypes.bool.isRequired,
  isKid: PropTypes.bool.isRequired,
  hasLinearGradient: PropTypes.bool,
  isMouseHoverRemoveIcon: PropTypes.bool,
  watchable: PropTypes.bool
}

PlayButton.defaultProps = {
  size: 'large',
  ctaPrefix: '',
  ctaSuffix: '',
  time: '',
  clickable: true,
  onClick: () => { },
  watchUrl: '#',
  className: '',
  ctaClass: '',
  ctaTimeClass: '',
  skipIntro: false,
  playIconWrapperClassName: '',
  hideTime: false,
  fromEpisodeCountdown: false,
  fromMyRentals: false,
  shouldUseRentalStyle: true,
  isMyRentalsBadge: false,
  e2eIdentifier: '',
  isRental: false,
  isKid: false,
  hasLinearGradient: false,
  watchable: false
}

export default PlayButton
